import { Controller } from '@hotwired/stimulus'

// Use this for explicit form submit (e.g. onchange)
export default class FormSubmit extends Controller {
  #previousInputValues = []

  connect() {
    this.#form.addEventListener('focusin', () => {
      this.#previousInputValues = new FormData(this.#form)
    })
  }

  submit() {
    if (this.#form.reportValidity()) {
      // If submit is a navigation (changes page url)
      if (this.#form.method === 'get') {
        // Restore form inputs so that the "old" page state is cached by turbo
        document.addEventListener('turbo:before-cache', this.#restoreInputs.bind(this), { once: true })
      }

      this.#form.requestSubmit()
    }
  }

  #restoreInputs() {
    this.#previousInputValues.forEach((value, key) => {
      const input = this.#form.elements[key]

      if (input) {
        if (input.type === 'checkbox' || input.type === 'radio') {
          input.checked = input.value === value
        } else {
          input.value = value
        }
      }
    })
  }

  get #form() {
    return this.element.closest('form')
  }
}
